export const ErrorPage = () => (
    <div className="App">
    <header className="App-header">
      
    <p>This page does not exist</p>
    <p>这个页面不存在</p>
    <p>Esta página no existe</p>
    <p>Diese Seite existiert nicht</p>
    <p>Questa pagina non esiste</p>
    <p>このページは存在しません</p>
    </header>
  </div>
);
