import React, { useEffect, useState } from "react";
import "./App.css";

import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('857cd121bc679e1ac067d9e0ffc0088a');

type QuizItem = {
  question: string;
  answer: string;
  explanation: string;
  completeAnswer: string;
};

const data = [
  {
    question: "Necesito este informe  (por/para)  mañana.",
    completeAnswer: "Necesito este informe para mañana.",
    answer: "para",
    explanation: "This sentence means 'I need this report by tomorrow.' The use of 'para' indicates a deadline or specific purpose.",
  },
  {
    question: "Gracias  (por/para)  la invitación a tu fiesta de cumpleaños.",
    completeAnswer: "Gracias por la invitación a tu fiesta de cumpleaños.",
    answer: "por",
    explanation: "This sentence means 'Thanks for the invitation to your birthday party.' 'Por' is used to express gratitude or reason.",
  },
  {
    question: "Viajamos a Madrid  (por/para)  avión.",
    completeAnswer: "Viajamos a Madrid por avión.",
    answer: "por",
    explanation: "This sentence means 'We travel to Madrid by plane.' 'Por' is used to indicate means of transportation.",
  },
  {
    question: "Este regalo es  (por/para)  ti.",
    completeAnswer: "Este regalo es para ti.",
    answer: "para",
    explanation: "This sentence means 'This gift is for you.' 'Para' is used to express purpose or recipient.",
  },
  {
    question: "Estoy estudiando  (por/para)  ser médico.",
    completeAnswer: "Estoy estudiando para ser médico.",
    answer: "para",
    explanation: "This sentence means 'I am studying to be a doctor.' 'Para' is used to express purpose or goal.",
  },
];

const shuffle = (array: QuizItem[]) => {
  return array
  .map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);
}

function App() {
  const [questions, setQuestions] = useState<QuizItem[]>(shuffle(data));

  const [showExplanation, setShowExplanation] = useState(false);
  const [showCorrectMessage, setShowCorrectMessage] = useState(false);
  const [hasAnswered, setHasAnswered] = useState(false);
  const question = questions[0];

  const answer = (data: string) => {
    if (hasAnswered) return;

    setHasAnswered(true);
    if (question?.answer === data) {
      handleCorrectAnswer();
    } else {
      handleWrongAnswer();
    }
  };


  const handleCorrectAnswer = () => {
    amplitude.track('Answered Correct');
    setShowCorrectMessage(true);
    setTimeout(() => {
      handleNextQuestion();
    }, 2800);
  };

  const handleWrongAnswer = () => {
    amplitude.track('Answered Wrong');
    setShowExplanation(true);
  };

  const reset = () => {
    setShowExplanation(false);
    setHasAnswered(false);
    setShowCorrectMessage(false);
  };

  const handleNextQuestion = () => {
    reset();

    const newQuestions = [...questions];
    newQuestions.shift();
    setQuestions(newQuestions);
  };
  

  useEffect(() => {
    if (questions.length !== 0) {
      amplitude.track('Shown question');
    } else {
      amplitude.track('Finished quiz');
    }
  }, [questions.length])

  const Questions = () => {
    return (
      <>
        <p>{question.question}</p>
        <div>
          {hasAnswered && <p>{question.completeAnswer}</p>}
          {showCorrectMessage && <p style={{color: "green"}}>Correct!</p>}
          {showExplanation && (
            <>
            <br />
            <p style={{color: "yellow"}}>Try Again!</p>
              <strong>{question.explanation}</strong>
              <br />
              <button onClick={() => handleNextQuestion()}>Next</button>
            </>
          )}
        </div>
        <div id="questionBox">
          <button disabled={hasAnswered} onClick={() => answer("por")}>Por</button>
          <button disabled={hasAnswered} onClick={() => answer("para")}>Para</button>

        </div>
      </>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        {questions.length === 0 && <p>Congrats, you finished!</p>}
        {questions.length !== 0 && <Questions />}
      </header>
    </div>
  );
}

export default App;
